import React from "react";
import { Select, MenuItem } from "@material-ui/core";

import { Optimization } from "../../grpc/silly_pb";

interface Props {
  value: Optimization;
  onChange: (value: Optimization) => void;
}

const OptimizationSelect: React.FC<Props> = ({ value, onChange }) => (
  <Select
    value={value}
    onChange={event => onChange(event.target.value as Optimization)}
  >
    <MenuItem value={Optimization.O_NONE}>-Onone</MenuItem>
    <MenuItem value={Optimization.O_SIZE}>-Osize</MenuItem>
    <MenuItem value={Optimization.O_UNCHECKED}>-Ounchecked</MenuItem>
    <MenuItem value={Optimization.O}>-O</MenuItem>
  </Select>
);

export default OptimizationSelect;
