import React from "react";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

interface Props {
  className?: string;
  open: boolean;
  onClose?: () => void;
}

const Alert: React.FC<Props> = ({ className, open, onClose, children }) => (
  <Snackbar className={className} open={open} onClose={onClose}>
    <MuiAlert severity="error" elevation={6} variant="filled">
      {children}
    </MuiAlert>
  </Snackbar>
);

export default Alert;
