import { useCallback, useState } from "react";
import useAPI, { Request } from "./useAPI";
import { Optimization } from "../../grpc/silly_pb";

export default function useEditor(initialRequest: Request) {
  const [request, setRequest] = useState<Request>(initialRequest);
  const [result, error, setEditor] = useAPI(request);
  const setCode = useCallback(
    (code: string) => {
      setRequest({ ...request, code });
    },
    [request]
  );
  const setOptimization = useCallback(
    (optimization: Optimization) => {
      setRequest({ ...request, optimization });
    },
    [request]
  );
  return [request, result, error, setCode, setOptimization, setEditor] as const;
}
