import { useState, useEffect } from "react";
import { useDebounce } from "use-debounce";
import { editor as MonacoEditor, MarkerSeverity } from "monaco-editor";

import { SillyServerClient } from "../../grpc/SillyServiceClientPb";
import { EmitSILRequest, Optimization } from "../../grpc/silly_pb";
import { Editor } from "../components/Editor";

export interface Request {
  code: string;
  optimization: Optimization;
}

export default function useAPI(request: Request) {
  const [result, setResult] = useState("");
  const [editor, setEditor] = useState<Editor>();
  const [error, setError] = useState<String>();

  const [debouncedRequest] = useDebounce(request, 1000);

  useEffect(() => {
    if (!editor || !debouncedRequest.code) {
      setResult("");
      return;
    }

    const client = new SillyServerClient(process.env.PUBLIC_URL);

    const request = new EmitSILRequest();
    request.setCode(debouncedRequest.code);
    request.setOptimization(debouncedRequest.optimization);

    const call = client.emitSIL(request, null, (error, response) => {
      if (error) {
        setError(`Error: ${error.message} (${error.code})`);
        console.error(error);
        return;
      }

      const errorMessage = response.getErrorMessage();
      const markers = parseErrorMessage(errorMessage);
      MonacoEditor.setModelMarkers(editor.getModel()!, "", markers);
      setResult(errorMessage || response.getSil());
      setError("");
    });

    return () => call.cancel();
  }, [debouncedRequest, setResult, editor]);

  return [result, error, setEditor] as const;
}

function parseErrorMessage(errorMessage: string): MonacoEditor.IMarkerData[] {
  let markers: MonacoEditor.IMarkerData[] = [];

  const lines = errorMessage.split("\n");
  for (const line of lines) {
    if (!line.length) continue;

    const matches = line.match(/^.+?:(\d+):(\d+): error: (.+$)/);
    if (!matches || (matches.length || 0) < 4) continue;

    const message = matches.pop() || "";
    const [, lineNumber, column] = matches.map(Number);

    markers.push({
      startLineNumber: lineNumber,
      startColumn: column,
      endLineNumber: lineNumber,
      endColumn: column,
      severity: MarkerSeverity.Error,
      message: message
    });
  }

  return markers;
}
