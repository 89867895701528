import React from "react";
import { editor as MonacoEditor } from "monaco-editor";
import ReactMonacoEditor, { MonacoDiffEditor } from "react-monaco-editor";

export type Editor = MonacoEditor.IStandaloneCodeEditor;

interface SwiftEditorProps {
  value?: string;
  onChange?: (value: string) => void;
  editorDidMount?: (editor: Editor) => void;
}

export const SwiftEditor = ({
  value,
  onChange,
  editorDidMount
}: SwiftEditorProps) => {
  return (
    <ReactMonacoEditor
      key="viewer"
      width="100%"
      height="100%"
      value={value}
      language="swift"
      theme="vs-dark"
      options={{ automaticLayout: true }}
      onChange={onChange}
      editorDidMount={editorDidMount}
    />
  );
};

interface SwiftDiffViewerProps {
  original?: string;
  value?: string;
}

export const SwiftDiffViewer = ({ original, value }: SwiftDiffViewerProps) => {
  return (
    <MonacoDiffEditor
      key="viewer"
      width="100%"
      height="100%"
      value={value}
      original={original}
      language="swift"
      theme="vs-dark"
      options={{ readOnly: true, automaticLayout: true }}
    />
  );
};
