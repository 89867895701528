import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  ThemeProvider,
  Grid,
  CssBaseline
} from "@material-ui/core";
import { Optimization } from "../grpc/silly_pb";

import useEditor from "./hooks/useEditor";
import useStyles from "./hooks/useStyle";
import { SwiftEditor, SwiftDiffViewer } from "./components/Editor";
import Alert from "./components/Alert";
import OptimizationSelect from "./components/OptimizationSelect";

const App: React.FC = () => {
  const [classes, theme] = useStyles();

  const [
    leftRequest,
    leftResult,
    leftError,
    setLeftCode,
    setLeftOptimization,
    setLeftEditor
  ] = useEditor({
    code: "",
    optimization: Optimization.O_NONE
  });

  const [
    rightRequest,
    rightResult,
    rightError,
    setRightCode,
    setRightOptimization,
    setRightEditor
  ] = useEditor({
    code: "",
    optimization: Optimization.O
  });

  const [isSmallAlertVisible, setSmallAlertVisible] = useState<boolean>(true);

  const error = leftError || rightError;

  return (
    <div className={classes.app}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <AppBar position="static">
          <Toolbar variant="dense">
            <Typography variant="h6">Silly</Typography>
          </Toolbar>
        </AppBar>

        <Grid container className={classes.container}>
          <Grid item xs={6}>
            <SwiftEditor
              onChange={setLeftCode}
              editorDidMount={setLeftEditor}
            />
          </Grid>
          <Grid item xs={6}>
            <SwiftEditor
              onChange={setRightCode}
              editorDidMount={setRightEditor}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={6}>
            <OptimizationSelect
              value={leftRequest.optimization}
              onChange={setLeftOptimization}
            />
          </Grid>

          <Grid item xs={6}>
            <OptimizationSelect
              value={rightRequest.optimization}
              onChange={setRightOptimization}
            />
          </Grid>
        </Grid>

        <Grid container className={classes.container}>
          <SwiftDiffViewer original={leftResult} value={rightResult} />
        </Grid>

        <Alert open={!!error}>{error}</Alert>

        <Alert
          open={isSmallAlertVisible}
          className={classes.smallScreenAlert}
          onClose={() => setSmallAlertVisible(false)}
        >
          This website is not optimized for small screens.
        </Alert>
      </ThemeProvider>
    </div>
  );
};

export default App;
