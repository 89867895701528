import { createMuiTheme, makeStyles } from "@material-ui/core";

export default function useStyle() {
  const theme = createMuiTheme({
    palette: {
      type: "dark",
      primary: { main: "#F05138" }
    }
  });

  const useStyles = makeStyles(theme => ({
    app: {
      height: "100vh",
      display: "flex",
      flexFlow: "column"
    },
    container: {
      flex: "1"
    },
    options: {
      flex: "0 60px",
      backgroundColor: theme.palette.background.default
    },
    smallScreenAlert: {
      [theme.breakpoints.up("sm")]: {
        display: "none"
      }
    }
  }));
  const classes = useStyles();
  return [classes, theme] as const;
}
